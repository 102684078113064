import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import ErrorService from "@/core/services/ErrorService";
import store from "@/core/services/store";
import ApiService from "@/core/services/api.service";

import {configure, extend, localize, ValidationObserver, ValidationProvider} from 'vee-validate';
import vi from 'vee-validate/dist/locale/vi.json';
import * as rules from 'vee-validate/dist/rules'
import VueMoment from 'vue-moment'
import moment from 'moment-timezone'
import VueTheMask from 'vue-the-mask'
import VCalendar from 'v-calendar';
import * as filters from '@/core/filters'; // global filters
import {VERIFY_AUTH} from "@/core/services/store/auth.module";
import {RESET_LAYOUT_CONFIG, SET_OLD_PATH} from "@/core/services/store/config.module";
import Swal from "sweetalert2";
import mixin from '@/core/common/commonFunction';

import Slick from 'vue-slick';
import 'slick-carousel/slick/slick.css';
import Paginate from 'vuejs-paginate'

import VueMeta from 'vue-meta'

Vue.use(VCalendar, {componentPrefix: 'edutalk'});

Vue.component('paginate', Paginate);

// install rules and localization
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});

configure({
    classes: {
        valid: 'is-valid',
        invalid: 'is-invalid',
        dirty: ['is-dirty', 'is-dirty'], // multiple classes per flag!
        // ...
    }
});

localize('vi', vi);
moment.locale('vi');
// Install components globally
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.config.productionTip = false;
// Handle all Vue errors
Vue.config.errorHandler = (error) => ErrorService.onError(error);
Vue.use(VueMoment, {moment});
Vue.use(Slick);

Vue.use(VCalendar, {componentPrefix: 'edutalk'});
Vue.use(VueTheMask);

// register global utility filters.
Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key]);
});

// Global 3rd party plugins
import i18n from "@/core/plugins/vue-i18n";
import "@/core/plugins/treeselect";
import "@/core/plugins/element.io";
import "@/core/plugins/apexcharts";
import "@/core/plugins/zingcharts";
import "@/core/plugins/good-table";
import "@/core/plugins/fb-messenger";
import {OPEN_MODAL} from "./core/services/store/customer/testInput.module";

// API service init
ApiService.init();

// Remove this to disable mock API
// MockService.init();

router.beforeEach(async (to, from, next) => {
    // Ensure we checked auth before each page load.
    const {authorize} = to.meta;
    const position = to.meta.position;
    let currentUser = store.getters.currentUser;
    let isKiemNhiem = position && currentUser && currentUser.user_positions && currentUser.user_positions.find((item) => {
        return position.includes(item.position);
    });
    let role_id = currentUser.profile && currentUser.profile.account_type ? currentUser.profile.account_type.noibo_role_id : '';
    if(role_id == '30' || role_id == '31' || role_id == '32' || role_id == '33' || role_id == '37' || role_id == '38' ) {
        if(currentUser.is_confirm == 2){
            if ((currentUser?.profile?.status_verify == 0 || currentUser?.profile?.status_verify == 3) && to.name != 'login') {
                Swal.fire({
                    title: 'Bạn cần hoàn tất thủ tục xác minh danh tính',
                    icon: 'warning',
                    showDenyButton: false,
                    showCancelButton: false,
                    confirmButtonText: `Đồng ý`,
                })
                if (to.name == 'profile') {
                    return next();
                } else {
                    return next({path: '/profile'});
                }
            }
        }

    }
    if (authorize) {
        if (!currentUser) {
            // not logged in so redirect to login page with the return url
            return next({path: '/login', query: {returnUrl: to.path}});
        }
        // check if route is restricted by role
        if (authorize.length && (currentUser && currentUser.permission && !currentUser.permission.includes(authorize)) && !isKiemNhiem) {
            // role not authorised so redirect to home page
            return next({path: '/dashboard'});
        }
    }

    if (to.name !== 'login' || isKiemNhiem) {
        store.dispatch(SET_OLD_PATH, to.path);
    }

    if(to.name==='maintain') return next();

    Promise.all([store.dispatch(VERIFY_AUTH)]).then(next);
    // reset config to initial state
    store.dispatch(RESET_LAYOUT_CONFIG);

    // Scroll page to top on every route change
    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 100);
    store.dispatch(OPEN_MODAL);
});
Vue.use(VueMeta)

Vue.mixin(mixin);
const app = new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
});
app.$mount("#app");
