// action types
import ApiService from "../../api.service"
import {SET_ERROR} from "../auth.module"

// export const CUSTOMER_LIST = "customer/list";
export const GET_TEST_INPUT_SCHEDULE = "customer/test-input-schedule"
export const CANCEL_TEST_INPUT_SCHEDULE = "test-input-schedule/cancel"
export const UPDATE_TEST_INPUT_SCHEDULE = "test-input-schedule/update"
export const CREATE_TEST_INPUT_SCHEDULE = "test-input-schedule/create"
export const CHECK_STATUS = "test-input/check-status"

export const GET_RESULT_EXAM = "test-input/get-point"
export const CHECK_SLOT_BY_DAY = "test-input/check-slot-by-day";
export const CREATE_TEST_INPUT_SCHEDULE_CUSTOMER = "test-input-schedule/customer";
export const GET_SPEAKING = 'test-input/get-speaking';
export const GET_SPEAKING_BY_TEST_INPUT = 'test-input/get-speaking-by-test-input';
export const STORE_ONLY_GENERAL = 'test-input/store-only-general'
export const STORE_SPEAKING_API = 'test-input/store-speaking-ai'
export const SWITCH_SPEAKING_API = 'test-input/switch-to-speaking-ai'
export const CHECK_LIST_WAITING_PROGRESSING = 'test-input/check-list-waiting-progressing';
export const HIDE_MODAL = 'HIDE_MODAL';
export const OPEN_MODAL = 'OPEN_MODAL';
export const IS_TIME_TO_CONFIRM = 'test-input/is-time-to-confirm';
export const CONFIRM_CUSTOMER_COME_TEST = 'test-input/confirm-customer-come-test';

// state types
const state = {
    payment: [],
    openModalMention: true
};

const getters = {
    openModalMention(state){
        return state.openModalMention;
}
};

const actions = {
    [GET_TEST_INPUT_SCHEDULE](context, id) {
        return new Promise(resolve => {
            ApiService.get(`/${GET_TEST_INPUT_SCHEDULE}`, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '')
                });
        });
    },
    [CHECK_SLOT_BY_DAY](context, payload) {
        return new Promise((resolve, redirect) => {
            ApiService.query(`/${CHECK_SLOT_BY_DAY}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    redirect(response);
                });
        });
    },
    [CHECK_STATUS](context, payload) {
        return new Promise((resolve, redirect) => {
            ApiService.query(`/${CHECK_STATUS}/${payload.id}`)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    redirect(response);
                });
        });
    },
    [CREATE_TEST_INPUT_SCHEDULE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('test-input-schedule', payload).then(({data}) => {
                resolve(data);
            })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [CANCEL_TEST_INPUT_SCHEDULE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`test-input-schedule/cancel/${payload}`).then(({data}) => {
                resolve(data);
            }).catch(({response}) => {
                reject(response)
            });
        })
    },
    [UPDATE_TEST_INPUT_SCHEDULE](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.update('test-input-schedule', payload.id, payload).then(({data}) => {
                resolve(data);
            })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [GET_RESULT_EXAM](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get(`${GET_RESULT_EXAM}`, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response)
                });
        });
    },
    [CHECK_SLOT_BY_DAY](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.query(`${CHECK_SLOT_BY_DAY}`, payload)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [CREATE_TEST_INPUT_SCHEDULE_CUSTOMER](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('test-input-schedule', payload).then(({data}) => {
                resolve(data);
            })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [GET_SPEAKING](context, id) {
        return new Promise(resolve => {
            ApiService.get(`/${GET_SPEAKING}`, id)
                .then(({data}) => {
                    resolve(data);
                })
                .catch(({response}) => {
                    resolve(response);
                    context.commit(SET_ERROR, response && response.data ? response.data.message : '')
                });
        });
    },
    [GET_SPEAKING_BY_TEST_INPUT](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`test-input/get-speaking-by-test-input/${payload.id}`, payload).then(({data}) => {
                resolve(data);
            })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [STORE_ONLY_GENERAL](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`test-input/store-only-general`, payload).then(({data}) => {
                resolve(data);
            })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },

    [STORE_SPEAKING_API](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post(`test-input/store-speaking-ai`, payload).then(({data}) => {
                resolve(data);
            })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [CHECK_LIST_WAITING_PROGRESSING](context, payload) {

        return new Promise((resolve, reject) => {
            ApiService.post('test-input/check-list-waiting-progressing', payload).then(({data}) => {
                resolve(data);
            })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [HIDE_MODAL](){
       state.openModalMention = false;
    },
    [OPEN_MODAL](){
        state.openModalMention = true;
    },
    [IS_TIME_TO_CONFIRM](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('test-input/is-time-to-confirm/'+payload.idTaskParent, payload).then(({data}) => {
                resolve(data);
            })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },
    [CONFIRM_CUSTOMER_COME_TEST](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('test-input/confirm-customer-come-test/'+payload.idTaskParent, {is_come:payload.isCome}).then(({data}) => {
                resolve(data);
            })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },

    [SWITCH_SPEAKING_API](context, payload) {
        return new Promise((resolve, reject) => {
            ApiService.post('test-input/switch-to-speaking-ai/'+payload.id, payload).then(({data}) => {
                resolve(data);
            })
                .catch(({response}) => {
                    reject(response);
                });
        });
    },

};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = error
    },
    showModal(state) {
        state.openModalMention = true;
    },
    hideModal(state) {
        state.openModalMention = false;
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};
